import type { MonitoringError } from './routes/api/error-monitoring/schema';

export const handleError = async ({ error, message }) => {
	console.error(error);

	try {
		// @ts-ignore
		const stack = error?.stack ?? null;
		const url = location.href;

		await fetch('/api/error-monitoring', {
			method: 'POST',
			headers: { Accept: 'application/json' },
			body: JSON.stringify({
				message,
				stack,
				url
			} satisfies MonitoringError)
		});
	} catch (e) {
		console.error(e);
	}

	return { message };
};
